<template>
  <CRow>
    <CCol sm="12">
      <CCard>
        <NoEndPoint />
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import NoEndPoint from '@/components/NoEndPoint.vue';

export default {
  name: 'UnitUsageHistory',
  components: {
    NoEndPoint,
  },
};
</script>
